import "../styles/globals.css";
import "../styles/style.css";
import { Provider } from "react-redux";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer } from "react-toastify";
import dynamic from "next/dynamic";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";

// import Header from "../components/MenuSection/header";
// import Footer from "../components/MenuSection/footer";
const Header = dynamic(() => import("../components/MenuSection/header"));
const Footer = dynamic(() => import("../components/MenuSection/footer"));
import store from "../redux/store";
import { saveState } from "../redux/localstorage";

function MyApp({ Component, pageProps }) {
  store.subscribe(() => {
    saveState({
      cart: store.getState().cart,
    });
  });

  const notificationStyle = {
    backgroundColor: "#2c7d3c",
  };

  const contextClass = {
    success: "#3399FF",
    error: "bg-red-600",
  };

  return (
    <>
      <Provider store={store}>
        <div className="flex flex-col min-h-screen">
          <Header />
          <div className="app grow bg-gray-8">
            <main className="">
              <Component {...pageProps} />
              <ToastContainer
                // toastClassName={({ type }) => contextClass[type || "success"]}
                autoClose={1500}
                // style={notificationStyle}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />
            </main>
            {/* )} */}
          </div>
          <div className="hidden lg:block">
            <Footer />
          </div>
        </div>
      </Provider>
    </>
  );
}

export default MyApp;
