import { configureStore } from "@reduxjs/toolkit";
import { cartReducer } from "./cart.slice";
import { loadState } from "../redux/localstorage";

const persistedState = loadState();
console.log(persistedState, "persistedState");

const initialStore = {
  persistedState,
};

const reducer = {
  cart: cartReducer,
};

const store = configureStore({
  reducer: reducer,
  preloadedState: loadState(), //call loadstate method to initiate store from localstorage
});
export default store;
