import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: { value: 0, stateLogin: false, userIsLogged: false },
  reducers: {
    incrementQuantity: (state, action) => {
      console.log(state, "state");

      return {
        ...state,
        value: state.value + action.payload,
      };
    },
    decrementQuantity: (state, action) => {
      return {
        ...state,
        value: state.value !== 0 && state.value - action.payload,
      };
    },
    resetQuantity: (state, action) => {
      return {
        ...state,
        value: state.value !== 0 && action.payload,
      };
    },
    loginPageState: (state, action) => {
      return {
        ...state,
        stateLogin: action.payload,
      };
    },
    userIsLoggedState: (state, action) => {
      return {
        ...state,
        userIsLogged: action.payload,
      };
    },
  },
});

export const cartReducer = cartSlice.reducer;

export const {
  incrementQuantity,
  decrementQuantity,
  resetQuantity,
  loginPageState,
  userIsLoggedState,
} = cartSlice.actions;
